import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { Head } from "../components/Head";
import { Layout } from "../components/Layout";

import { 
  SectionContainer,
  SectionWrapper
} from "../components/Section";

import { Carousel } from "../components/Carousel";
import { Flexbox } from "../components/Flexbox";
import { Grid } from "../components/Grid";
import { IndexHero } from "../components/Hero";
import { Image } from "../components/Media";
import { Ticker } from "../components/Ticker";


const IndexPage: React.FC = () => {
  const data: any = useStaticQuery(graphql`
    query {
      homepage: mdx(
        frontmatter: {
          title: {
            eq: "Home"
          }
        }
      ) {
        frontmatter {
          seo_title
          seo_description
          heading
          hero_image {
            mobile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                  transformOptions: {
                    fit: CONTAIN
                  }
                )
              }
            }
            desktop {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                  transformOptions: {
                    fit: CONTAIN
                  }
                )
              }
            }
          }
          benefits {
            svg
            heading
          }
          about_image {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
                transformOptions: {
                  fit: CONTAIN
                }
              )
            }
          }
          call_to_action_image {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
                transformOptions: {
                  fit: CONTAIN
                }
              )
            }
          }
          location_image {
            mobile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            desktop {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }

      clients: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "client"
            }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              svg
            }
          }
        }
      }

      projects: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "project"
            }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              image {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                    transformOptions: {
                      fit: CONTAIN
                    }
                  )
                }
              }
            }
          }
        }
      }

      services: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "service"
            }
          }
        }
        sort: {
          fields: [frontmatter___order]
          order: ASC
        }
      ) {
        edges {
          node {
            frontmatter {
              heading
              bullets
              image {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }

      testimonials: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "testimonial"
            }
          }
        }
        sort: {
          fields: [frontmatter___order]
          order: ASC
        }
      ) {
        edges {
          node {
            frontmatter {
              author
              testimonial
              image {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              source
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Head
        title={ data.homepage.frontmatter.seo_title }
        description={ data.homepage.frontmatter.seo_description }
      />

      <SectionWrapper
        background="background.azureishwhite"
        padding={{
          base: '38px 0 32px',
          lg: '60px 0 48px'
        }}
      >
        <SectionContainer>
          <IndexHero
            node={ data.homepage.frontmatter }
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        id="about"
        background={{
          base: 'linear-gradient(white 0%, white 80%, #DBE9F8 80%, #DBE9F8 100%)',
          sm: 'transparent'
        }}
        padding="120px 0 60px"
      >
        <SectionContainer>
          <Flexbox
            nodes={[
              {
                node: {
                  heading: "Our Story",
                  description: [
                    "Destiny Roofing Corp. is a roofing contractor serving all of Ontario. Our roofing service. We provide expert roofing construction for both low-rise and high-rise commercial properties as well as residential buildings. Destiny Roofing Corp. is known for offering top commercial, industrial, institutional, municipal and residential roofing services in Brampton and across the entire Greater Toronto Area (GTA). We have combined 100+ years of experience and proven track record for safe and trusted installations, repairs, and maintenance.",
                    "Call now for a free consultation, or fill out the contact form below, and we will call you back as soon as possible."
                  ],
                  image: data.homepage.frontmatter.about_image.childImageSharp.gatsbyImageData,
                  hasCallButton: true,
                  hasImageFrame: true
                }
              }
            ]}
            layout="image-with-text"
            childNodes={ [] }
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="120px 0 0"
      >
        <SectionContainer>
          <Ticker
            nodes={ data.clients.edges }
            template="client"
            itemWidth={ 500 }
            heading="Preferred & Trusted By"
            description="Our Satisified Clients"
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        id="services"
        background={{
          base: 'linear-gradient(white 0%, white 18%, #DBE9F8 18%, #DBE9F8 100%)',
          lg: 'linear-gradient(white 0%, white 46%, #DBE9F8 46%, #DBE9F8 100%)'
        }}
        padding="120px 0 60px"
      >
        <SectionContainer>
          <Grid
            nodes={ data.services.edges }
            template="service"
            heading="Our Services"
            description="What We Do"
            templateColumns={{
              base: '1fr',
              lg: '1fr 1fr 1fr'
            }}
            gap={{
              base: '24px'
            }}
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        id="projects"
        padding="120px 0"
      >
        <SectionContainer>
          <Carousel
            nodes={ data.projects.edges }
            options={{
              loop: true
            }}
            template="project"
            heading="Our Projects"
            description="We have been honored to work with many home and business owners throughout Ontario."
            style={{
              align: "center",
              textAlign: "center"
            }}
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        background="background.azureishwhite"
        margin={{
          lg: '120px 0 0'
        }}
      >
        <SectionContainer>
          <Flexbox
            nodes={[
              {
                node: {
                  heading: "Get Your Roofing Project Started Today.",
                  image: data.homepage.frontmatter.call_to_action_image.childImageSharp.gatsbyImageData,
                  hasCallButton: true,
                  style: {
                    gap: {
                      base: '24px',
                      lg: '72px'
                    }
                  }
                }
              }
            ]}
            layout="call-to-action"
            childNodes={ [] }
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="120px 0 0"
      >
        <SectionContainer>
          <Carousel
            nodes={ data.testimonials.edges }
            options={{
              loop: true,
              skipSnaps: false
            }}
            template="testimonial"
            splitView
            heading="Customer Reviews"
            style={{
              align: "flex-start"
            }}
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="120px 0 0"
      >
        <SectionContainer>
          <Image
            node={ data.homepage.frontmatter }
            style={{
              borderRadius: '18px'
            }}
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        id="contact"
        padding="120px 0 60px"
      >
        <SectionContainer>
          <Flexbox
            nodes={[
              {
                node: {
                  style: {
                    templateColumns: {
                      base: '1fr',
                      lg: '1fr 1fr'
                    },
                    gap: {
                      base: '32px',
                      lg: '72px'
                    }
                  }
                }
              }
            ]}
            layout="contact"
            childNodes={ [] }
            heading="Contact Us"
            description="We're here to help."
          />
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  )
}


export default IndexPage;
